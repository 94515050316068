import {
  CheckCircleRounded,
  Comment,
  Delete,
  Edit,
  Favorite,
  ModeComment,
  Star,
  Visibility,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  IconButton,
  Rating,
  styled,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { userTypeContext } from "../../../App";
import Valorant from "../../../images/Valorant.jpg";
import "./ServiceCardItem.css";

const ServiceCardItem = (props) => {
  const navigate = useNavigate();
  const [userTypeInfo, setUserTypeInfo] = useContext(userTypeContext);
  const { data, setRender, handleUpServiceOpen, editOff } = props;
  const {
    company,
    post_time,
    poster,
    poster_info,
    agency_info,
    poster_pic,
    service_category,
    service_duration,
    service_info,
    service_pic,
    service_pic_list,
    service_price,
    service_refer,
    service_review,
    poster_name,
    offer_st,
    user_state,

    service_currency,
    service_review_quantity,
    service_subcategory,
    service_title,
  } = data;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    // arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  };
  return (
    <Card
      sx={{
        width: "100%",
        height: "auto",
        borderRadius: "12px",
        "&:hover": {
          boxShadow: "5px 5px 15px grey;",
        },
      }}
    >
      <Stack className="service" sx={{}}>
        <Box
          sx={{ height: "40%", position: "relative" }}
          className="serviceSlick"
        >
          <Slider {...settings} sx={{}}>
            <Box sx={{ height: "20vh" }}>
              <CardMedia
                component="img"
                height="100%"
                image={
                  service_pic?.[0]
                    ? "//yeapbe.com/" + service_pic?.[0]?.pic_url
                    : "https://ghorami.com/image/web_admin/data_entry.jpg"
                }
                alt={"Stylin"}
                sx={{ opacity: 0.9, cursor: "pointer" }}
                onClick={() => {
                  navigate("/servicedetails/" + service_refer);
                  setRender && setRender(service_refer);
                }}
              />
            </Box>
            {service_pic_list?.map((element) => (
              <Box key={element} sx={{ height: "20vh" }}>
                <CardMedia
                  component="img"
                  height="100%"
                  image={element?.mpic}
                  alt={"service_pic"}
                  sx={{ opacity: 0.9, cursor: "pointer" }}
                  onClick={() => {
                    navigate("/servicedetails/" + service_refer);
                    setRender && setRender(service_refer);
                  }}
                />
              </Box>
            ))}
          </Slider>
          {true && (
            <Box
              sx={{
                position: "absolute",
                top: "4%",
                right: "2%",
                padding: "1% 2%",
                backgroundColor: "black",
                borderRadius: "5px",
              }}
            >
              <Typography
                variant="p"
                sx={{ color: "white", fontSize: "11pt", fontWeight: 600 }}
              >
                {service_price == "0" ||
                service_price == 1 ||
                service_price == ""
                  ? "Negotiate"
                  : service_price}
                {service_price == 1 || service_price == ""
                  ? " "
                  : service_currency
                  ? service_currency
                  : " BDT"}{" "}
              </Typography>
            </Box>
          )}
        </Box>

        <Stack sx={{ position: "relative", cursor: "pointer", height: "auto" }}>
          <Stack sx={{ padding: "7%", mt: "9px" }}>
            <Box
              sx={{
                // border: "7px solid white",
                position: "absolute",
                top: "-30px",
                left: "18px",
              }}
            >
              <StyledBadge
                badgeContent={""}
                color={user_state?.[0]?.states === 1 ? "success" : "warning"}
                variant="dot"
              >
                <Avatar
                  alt="Remy Sharp"
                  src={
                    agency_info?.agency_logo?.[0]
                      ? "//yeapbe.com/" + agency_info?.agency_logo?.[0]?.pic_url
                      : poster_info?.user_pic?.[0]
                      ? "//yeapbe.com/" + poster_info?.user_pic?.[0]?.pic_url
                      : "//ghorami.com/image/web_admin/data_entry.jpg"
                  }
                  sx={{
                    border: "7px solid white",
                    width: 50,
                    height: 50,
                  }}
                />
              </StyledBadge>
            </Box>
            {/* <Typography
              variant="p"
              sx={{
                fontSize: { xs: "10pt", md: "11pt" },
                fontWeight: 500,
                position: "absolute",
                top: -5,
                left: "28%",
                color: "gray",
              }}
            >
              {poster_name}
            </Typography> */}
            {/* <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: "5px" }}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: { xs: "11pt", md: "11pt" },
                  fontWeight: 600,
                  color: "57b50c",
                }}
              ></Typography>
            </Stack> */}
            <Stack
              spacing={0}
              direction="column"
              justifyContent="left"
              alignItems="flex-start"
              // sx={{ height: "90px" }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { xs: "10pt", md: "10pt" },
                    fontWeight: 600,
                    color: "#22AB7D",
                  }}
                  onClick={() => {
                    navigate("/servicedetails/" + service_refer);
                  }}
                >
                  {/* {job_title?.slice(0, 25)}
                   {job_title?.length >= 25 && "..."} */}
                  {service_category?.[0]?.title} ||{" "}
                  {service_subcategory?.[0]?.title?.slice(0, 9)}{" "}
                  {service_subcategory?.[0]?.title?.length >= 9 && "..."}
                </Typography>
                {!editOff && userTypeInfo?.poster === poster && (
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => {
                      handleUpServiceOpen(service_refer, offer_st);
                    }}
                  >
                    <Edit fontSize="inherit" />
                  </IconButton>
                )}
                {/* {userTypeInfo?.poster === poster && (
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      handleUpServiceOpen(service_refer, offer_st);
                    }}
                  >
                    <Delete fontSize="inherit" />
                  </IconButton>
                )} */}
              </Stack>

              <Box sx={{ height: "43px" }}>
                <Typography
                  gutterBottom
                  variant="p"
                  color="black"
                  sx={{
                    fontSize: { xs: "11pt", md: "13pt" },
                    fontWeight: 500,
                    lineHeight: "15pt",
                  }}
                  component="div"
                  onClick={() => {
                    navigate("/servicedetails/" + service_refer);
                  }}
                >
                  {service_title?.slice(0, 45)}
                </Typography>
              </Box>

              <Stack
                spacing={2}
                direction="row"
                justifyContent="left"
                alignItems="center"
                // sx={{ marginTop: "10px" }}
              >
                <Stack
                  spacing={0}
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                >
                  <Rating
                    name="read-only"
                    value={service_review?.average_rating}
                    readOnly
                    sx={{
                      fontSize: { xs: "7pt", md: "12pt" },
                    }}
                  />

                  <Typography
                    variant="p"
                    color="black"
                    sx={{
                      ml: "2px",
                      fontSize: { xs: "7pt", md: "10pt" },
                      fontWeight: 600,
                    }}
                  >
                    {service_review?.average_rating} (
                    {service_review?.total_review} Reviews)
                  </Typography>
                </Stack>
              </Stack>

              {/* <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems="center"
                // spacing={2}
                sx={{ width: "100%", marginTop: "8px" }}
              >
                <Button
                  variant="contained"
                  sx={{
                    // marginTop: "15px",
                    width: "45%",
                    borderRadius: "30px",
                    backgroundColor: "#14506A",
                    fontSize: "10pt",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#22AB7D",
                    },
                  }}
                  onClick={() => {
                    navigate("/servicedetails/" + service_refer);
                  }}
                  size="small"
                >
                  Details
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    // marginTop: "15px",
                    width: "45%",
                    borderRadius: "30px",
                    backgroundColor: "#22AB7D",
                    fontSize: "10pt",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#14506A",
                    },
                  }}
                  href="https://ghorami.com/profile/login/login.php"
                  target={"_blank"}
                  // onClick={() => {
                  //   navigate("/booknow/" + service_refer);
                  // }}
                  size="small"
                >
                  Book now
                </Button>
              </Stack> */}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default ServiceCardItem;
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 9,
    top: 11,
    border: `3px solid ${theme.palette.background.paper}`,
    padding: "5px",
    borderRadius: "50%",
  },
}));
