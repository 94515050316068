import { WatchLater } from "@material-ui/icons";
import {
  AccessTime,
  Badge,
  CheckCircleRounded,
  Engineering,
  Favorite,
  LocationOn,
  Psychology,
  Schedule,
  WorkHistory,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import JobDetailsModal from "../../JobList Components/JobDetailsModal";
import "./Job.css";

const JobShortCard = (props) => {
  const navigate = useNavigate();
  const { jobData } = props;
  const {
    job_budget,
    job_title,
    job_category,
    job_currency,
    job_expertlevel,
    job_freelancer,
    job_hour,
    job_id,
    job_info,
    poster_locat,
    job_pay_type,
    job_poster,
    job_poster_pic,
    job_poster_name,
    job_refer,
    job_schedule,
    job_skill,
    job_state,
    job_time,
    job_type,
    job_visibility,
    poster_status,
  } = jobData;
  let skill = [];
  skill = job_skill && JSON.parse(job_skill);

  // const [jobId, setJobId] = useState("");
  // const [jobRefer, setJobRefer] = useState("");
  // const [poid, setPoid] = useState("");
  // const [test, setTest] = useState(false);

  // const [open, setOpen] = useState(false);
  // const handleOpen = (refer, id, poid) => {
  //   setOpen(true);
  //   setJobId(id);
  //   setJobRefer(refer);
  //   setPoid(poid);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleTest = () => {
  //   setTest(!test);
  // };
  return (
    <Paper
      elevation={3}
      onClick={() => {
        navigate("/JobDetails/" + job_id + "/" + job_refer + "/" + job_poster);
      }}
      sx={{
        padding: "5px",
        cursor: "pointer",
        borderRadius: "12px",
        "&:hover": {
          boxShadow: "5px 5px 15px grey;",
        },
      }}
    >
      <Grid
        container
        spacing={0}
        rowSpacing={1}
        sx={{ padding: { md: "20px 10px" } }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Stack spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Stack spacing={1} sx={{ width: "90%" }}>
                <Stack alignItems="flex-left" justifyContent={"center"}>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "14pt", fontWeight: 600 }}
                  >
                    {job_title?.slice(0, 25)}
                    {job_title?.length >= 25 && "..."}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "11pt", fontWeight: 700 }}
                  >
                    {job_currency} {job_budget}
                  </Typography>
                </Stack>
                <Stack
                  spacing={1}
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"left"}
                >
                  <Stack
                    spacing={0.5}
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"left"}
                  >
                    <Psychology style={{ fontSize: "14pt" }} />

                    <Typography
                      variant="p"
                      sx={{ color: "gray", fontSize: "11pt", ml: "4px" }}
                    >
                      {job_expertlevel}
                    </Typography>
                  </Stack>
                  {/* <Typography
                    variant="p"
                    sx={{ color: "gray", fontSize: "12pt", ml: "4px" }}
                  >
                    {job_currency} {job_budget}
                  </Typography> */}
                  <Stack
                    spacing={0.5}
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"left"}
                  >
                    <WatchLater style={{ fontSize: "13pt" }} />

                    <Typography
                      variant="p"
                      sx={{ color: "gray", fontSize: "11pt", ml: "4px" }}
                    >
                      {job_time}
                    </Typography>
                  </Stack>
                </Stack>
                <Typography variant="p" sx={{ fontSize: "11pt" }}>
                  {/* {job_info + "..."} */}
                  {job_info && job_info?.slice(0, 80) + "..."}
                </Typography>
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="left"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  {skill?.length > 0 ? (
                    skill?.slice(0, 2).map((element) => (
                      <Button
                        sx={{
                          fontSize: "8pt",
                          color: "white",
                          backgroundColor: "darkgreen",
                          testTransform: "capitalize",
                          paddingX: "10px",
                          paddingY: "0px",
                          borderRadius: "30px",
                          "&:hover": {
                            backgroundColor: "#22AB7D",
                            color: "white",
                          },
                        }}
                        onClick={() => {
                          navigate("/joblist");
                        }}
                        size="small"
                        key={element}
                      >
                        {element}
                      </Button>
                    ))
                  ) : (
                    <Button
                      sx={{
                        fontSize: "8pt",
                        color: "white",
                        backgroundColor: "darkgreen",
                        testTransform: "capitalize",
                        paddingX: "10px",
                        paddingY: "0px",
                        borderRadius: "30px",
                        "&:hover": {
                          backgroundColor: "#22AB7D",
                          color: "white",
                        },
                      }}
                      size="small"
                      key={"none"}
                    >
                      all
                    </Button>
                  )}
                </Stack>
                <Stack
                  spacing={0}
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"left"}
                >
                  {poster_status == "1" ? (
                    <CheckCircleRounded
                      style={{ fontSize: "12pt", color: "darkgreen" }}
                    />
                  ) : (
                    <CheckCircleRounded
                      style={{ fontSize: "12pt", color: "gray" }}
                    />
                  )}

                  <Typography
                    variant="p"
                    sx={{ color: "gray", fontSize: "11pt", ml: "4px" }}
                  >
                    {job_poster_name}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ color: "gray", fontSize: "11pt", ml: "4px" }}
                  >
                    ||
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ color: "gray", fontSize: "11pt", ml: "4px" }}
                  >
                    {poster_locat}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      {/* <JobDetailsModal
        handleClose={handleClose}
        jobId={jobId}
        jobRefer={jobRefer}
        poid={poid}
        open={open}
        test={test}
        setOpen={setOpen}
        handleTest={handleTest}
        // style={style}
      /> */}
    </Paper>
  );
};

export default JobShortCard;
